import React, { useState, useEffect } from 'react'
import ListenAndPoint from './../engines_v2/ListenAndPoint';
import ListenAndR from './../engines_v2/ListenAndRepeat';
import ListenAndPointSvg from './../engines_v2/ListenAndPointSvg';

import ListenStickAndWrite from './../engines_v2/ListenStickAndWrite';
import WatchVideo from './../engines_v2/WatchVideo';
import ReadAndMatch from './../engines_v2/ReadAndMatchV2';
import ReadAndMatchV1 from './../engines_v2/ReadAndMatch';

import ListenReadAndSay from './../engines_v2/hello';
import LookSay from './../engines_v2/LookSay';
import CompleteWrite from './../engines_v2/CompleteWrite';
import CompleteV2 from './../engines_v2/CompleteV2';
import CompleteV3 from './../engines_v2/CompleteV3';

import Downloadattach from './../engines_v2/Downloadattach';

import CompleteV2Rev1 from './../engines_v2/CompleteV2Rev1';
import CompleteV2Rev1V2 from './../engines_v2/CompleteV2Rev1V2';
import CompleteV2Rev1Form from './../engines_v2/CompleteV2Rev1Form';
import CompleteV2Rev1Line from './../engines_v2/CompleteV2Rev1Line'

import CompleteV2RevChart1 from './../engines_v2/CompleteV2RevChart1'
import CompleteRevTable from './../engines_v2/CompleteRevTable';

import CircleLetter from './../engines_v2/CircleLetter';
import CircleWords from './../engines_v2/CircleWords';

import WhoDoYouWantToBe from './../engines_v2/WhoDoYouWantToBe';


import LookReadAndWrite from './../engines_v2/LookReadAndWrite';
import TalkToFriend from './../engines_v2/TalkToFriend';
import ListenReadSvg from './../engines_v2/ListenReadSvg';

import ListenAndRead from './../engines_v2/ListenAndRead';
import ReadStory from './../engines_v2/ReadStory';
import SortingPicture from '../engines_v2/SortingPicture';
import ListenAndReadV2 from './../engines_v2/ListenAndReadV2';
import ListenAndReadV3 from './../engines_v2/ListenAndReadV3';
import ListenAndReadV3Table from './../engines_v2/ListenAndReadV3Table';
import TableDataStyle from './../engines_v2/TableDataStyle';


import ListenAndReadV3Message from './../engines_v2/ListenAndReadV3Message';
import LookListenSay from './../engines_v2/LookListenSay';
import LookListenSayV1 from './../engines_v2/LookListenSayV1';
import LookListenSayVSvg1 from './../engines_v2/LookListenSayVSvg1';
import DragShape from './../engines_v2/DragShape';

import Circle from './../engines_v2/circle';
import CircleMultiple from './../engines_v2/CircleMultiple';
import LookAndWriteV2 from './../engines_v2/LookAndWrite2';
import LookAndWriteV3 from './../engines_v2/LookAndWrite3';
import LookAndWriteV4 from './../engines_v2/LookAndWrite4';
import ReadYourDialog from './../engines_v2/ReadYourDialog';

import ColorCircle from './../engines_v2/colorCircle';
import ColorCircleV2 from './../engines_v2/colorCircleV2';
import Followinstruction from './../engines_v2/Followinstruction'

import LookAndSay from './../engines_v2/LookAndSay';
import LookAndSayV2 from './../engines_v2/LookAndSayV2';

import MatchingDemo from './../engines_v2/MatchingDemo';
import MatchingDemo2 from './../engines_v2/MatchingDemo2';
import Dragtable from './../engines_v2/Dragtable';
import DragtableV2 from './../engines_v2/Dragtable_v2';
import DragtableV2Text from './../engines_v2/Dragtable_v2_text';

import OrderWords from './../engines_v2/OrderWords';


import LookNotice from './../engines_v2/LookNotice';
import LookNoticeV2 from './../engines_v2/LookNoticeV2';

import MultipleCheckbox from './../engines_v2/MultipleCheckbox';

import MultipleCheckboxCount from './../engines_v2/MultipleCheckboxCount';

import MapDrag from './../engines_v2/MapDrag';


import Goodjob from './../engines_v2/goodjob';
import GoodjobV from './../engines_v2/GoodjobV2.js';

import GameL1 from './../pages/game';


import MeetTheCharactersL1 from './../pages/MeetTheCharaters';
import MeetTheCharactersV2 from './../engines_v2/MeetTheCharactersV2';

import DragAndDropL1 from './../pages/Drag';

import LookAndSayL1 from './../pages/LookAndSay'

import WatchVideoL1 from './../pages/WatchVideo'
import ReadAndMatchL1 from './../pages/ReadAndMatch'
import SortingL1 from './../pages/sorting'

import ListenReadAndSayL1 from './../pages/ListenReadAndSay'
import LookSayL1 from './../pages/LookSay'
import ChooseAndHearL1 from './../pages/ChooseAndHear';
import LookAndReadAndWriteL1 from './../pages/LookAndReadAndWrite';
import TalkToFriendL1 from './../pages/TalkToFriend'

import ListenAndReadL1 from './../pages/ListenAndRead';
import CompleteL1 from './../pages/Complete'
import LookListenSayL1 from './../pages/LookListenSay';
import CircleHearL1 from './../pages/CircleHear';
import ReadNamesL1 from './../pages/ReadNames';
import LookAndWriteL1 from './../pages/LookAndWrite';
import LookNoticeL1 from './../pages/LookAndNotice';
import GoodJobL1 from './../pages/goodjob';
import ImageTxt from './../engines_v2/ImageTxt'










import VerticalMenu from './../components/VerticalMenuV2';

import _ from 'lodash';
import HeaderTop from './../components/headerTop';
import HeaderTopV3 from './../components/headerTopV3';

import { useParams } from "react-router-dom";
import axios from 'axios';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle: "Listen and point."
}

function Game() {
    const [questions, setQuestions] = useState([]);

    const [current, setCurrent] = useState(0);
    const [unitData, setUnitData] = useState({});
    const [exerciseData, setExerciseData] = useState({});
    const [userAnswers, setUserAnswers] = useState([]);

    const [mainTitle, setMainTitle] = useState("")

    let { level, unit } = useParams();

    useEffect(() => {

        let current_hash = window.location.hash;


        if (current_hash === "") {
            current_hash = 0;
        } else {
            current_hash = parseInt(current_hash.replace("#", ""));
            current_hash = current_hash - 1;

            if (window.location.hash !== "#video") {
                setCurrent(current_hash)
            }

        }

        level = level.replace("L", "level");
        unit = unit.replace("U", "unit");
        unit = unit.replace("Read", "read");

        unit = unit.replace("R", "rev");

        axios.get(`${baseurl}assets/levels/${level}/${unit}/${unit}.json`).then((res) => {
            setUnitData(res.data);
            setMainTitle(res.data.mainTitle);

            if (window.location.hash !== "#video") {

                if (res.data.exercise.length > 0) {
                    setExerciseData(res.data.exercise[current_hash])
                }
            } else {

                let videoLink = "assets/levels/level1/unit2/video/2.mp4";
                if (unit !== "unit2") {
                    videoLink = "assets/videos/" + unit + ".mp4";
                }

                setExerciseData({ type: "video", title: "Watch the video.", icon: "play", video: videoLink, audio: "assets/levels/level1/unit1/assets/audios/watch_video.mp3" })
            }


        }).catch((err) => {

        });


        window.addEventListener('hashchange', function () {
            let current_hash = window.location.hash;
            if (current_hash === "") {
                current_hash = 0;
            } else {
                current_hash = parseInt(current_hash.replace("#", ""));
                current_hash = current_hash - 1;

                if (window.location.hash !== "#video") {
                    setCurrent(current_hash)
                }
            }

            level = level.replace("L", "level");
            unit = unit.replace("U", "unit");
            unit = unit.replace("Read", "read");
            unit = unit.replace("R", "rev");

            axios.get(`${baseurl}assets/levels/${level}/${unit}/${unit}.json`).then((res) => {

                if (window.location.hash !== "#video") {
                    setMainTitle(res.data.mainTitle);
                    if (res.data.exercise.length > 0) {

                        //  console.log(res.data.exercise[current_hash])
                        setExerciseData(res.data.exercise[current_hash])
                    }
                } else {
                    let videoLink = "assets/levels/level1/unit2/video/2.mp4";
                    if (unit !== "unit2") {
                        videoLink = "assets/videos/" + unit + ".mp4";
                    }
                    setExerciseData({ type: "video", title: "Watch the video", icon: "play", video: videoLink, audio: "assets/levels/level1/unit1/assets/audios/watch_video.mp3" })

                }
            });







        });





        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";



    }, [])

    const nextExerciseV2 = (answerItem) => {

        console.log("answerItem_data", answerItem)
        let userAnswer2 = [...userAnswers];

        if (answerItem !== undefined) {
            userAnswer2.push(answerItem);
            setUserAnswers(userAnswer2);
        }

        if (answerItem.type === "look-notice") {

            let userId = localStorage.getItem("userId");

            let gondoland = localStorage.getItem("gondoland");

            if (window.location.host.search("student.migplatform.com") !== -1) {
                axios({
                    url: "https://api.migplatform.com/user/pushData",
                    method: "post",
                    data: { data: userAnswer2, userId: userId, level: level, unit: unit }
                }).then((resData) => {

                });
            } else {
                axios({
                    url: process.env.REACT_APP_BACKEND_URL + "/user/pushData",
                    method: "post",
                    data: { data: userAnswer2, userId: userId, level: level, unit: unit }
                }).then((resData) => {

                });
            }

        }
    }
    const nextExercise = (answerItem) => {

        console.log("answerItem_data", answerItem)
        let userAnswer2 = [...userAnswers];
        if (answerItem !== undefined) {
            userAnswer2.push(answerItem);
            setUserAnswers(userAnswer2);
        }

        let current_hash = window.location.hash



        current_hash = parseInt(current_hash.replace("#", ""));

        current_hash++;


        // alert(unitData.exercise[current_hash - 1].type);
        if (unitData.exercise[current_hash - 1].type === "good_job" || unitData.exercise[current_hash - 1].type === "good_job_v2") {

            let userId = localStorage.getItem("userId");

            let gondoland = localStorage.getItem("gondoland");

            if (window.location.host.search("student.migplatform.com") !== -1) {
                axios({
                    url: "https://api.migplatform.com/user/pushData",
                    method: "post",
                    data: { data: userAnswer2, userId: userId, level: level, unit: unit }
                }).then((resData) => {

                });
            } else {
                axios({
                    url: process.env.REACT_APP_BACKEND_URL + "/user/pushData",
                    method: "post",
                    data: { data: userAnswer2, userId: userId, level: level, unit: unit }
                }).then((resData) => {

                });
            }

        }


        if (unitData.exercise[current_hash - 1] !== undefined) {
            window.location.hash = current_hash;
            setCurrent(current_hash - 1);
        }

    }

    const prevExercise = () => {

        let current_hash = window.location.hash

        current_hash = parseInt(current_hash.replace("#", ""));

        current_hash--;

        if (unitData.exercise[current_hash - 1] !== undefined) {
            window.location.hash = current_hash;
            setCurrent(current_hash - 1);
        }

    }
    return (<div id="mainapp" className={"gameapp " + exerciseData.type} style={{ color: "#fff" }} >
        <div id={level === "L1" && unit === "U1" ? unit !== "U1" ? "game-container" : "t1" : "game-container"}>
            {level === "L1" && unit === "U1" ? unit !== "U1" && <VerticalMenu active={"Exercise"} unit={unit} level={level} /> : <VerticalMenu active={"Exercise"} unit={unit} level={level} />}
            <div id={level === "L1" && unit === "U1" ? unit !== "U1" ? "mainquestion" : "t112" : "mainquestion"}>


                {level === "L1" && unit === "U1" ?
                    unit !== "U1" && <HeaderTopV3 title={mainTitle} level={level} unit={unit} progress={unitData.exercise !== undefined ? ((current + 1) / unitData.exercise.length) * 100 : 0} /> :

                    <HeaderTopV3 title={mainTitle} level={level} unit={unit} progress={unitData.exercise !== undefined ? ((current + 1) / unitData.exercise.length) * 100 : 0} />
                }

                {/* <HeaderTopV3 title={mainTitle} level={"2"} unit={unit} progress={unitData.exercise !== undefined? ( (current + 1) / unitData.exercise.length) * 100:0} /> */}
                {/* <div style={{background:"red",color:"red",width:"50%",minHeight:"500px"}}>

                        <MatchingDemo />
                </div> */}

                {exerciseData.type !== undefined &&
                    <>
                        {exerciseData.type === "listen_repeat" && <ListenAndR questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "listen_point" && <ListenAndPoint questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "listen_point_svg" && <ListenAndPointSvg questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "listen_stick_write" && <ListenStickAndWrite questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "video" && <WatchVideo questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "read_match" && <ReadAndMatch questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "listen_read_say" && <ListenReadAndSay questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_say" && <LookSay questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "complete_m_am" && <CompleteWrite questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_read_write" && <LookReadAndWrite questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "talk_to_friend" && <TalkToFriend questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "listen_read_svg_v3" && <ListenReadSvg questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "listen_read" && <ListenAndRead questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "read_story" && <ReadStory questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "sorting_picture" && <SortingPicture questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "listen_read_v2" && <ListenAndReadV2 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "listen_read_v3" && <ListenAndReadV3 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "listen_read_v3_table" && <ListenAndReadV3Table questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "TableDataStyle" && <TableDataStyle questions={exerciseData} nextExercise={nextExercise} />}


                        {exerciseData.type === "listen_read_v3_messages" && <ListenAndReadV3Message questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "listen_read_svg" && <ListenAndReadV3 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "look_listen_say" && <LookListenSay questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "circle_hear" && <Circle questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "circle_hear_multiple" && <CircleMultiple questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "look_write_v2" && <LookAndWriteV2 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_write_v3" && <LookAndWriteV3 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "LookAndSay" && <LookAndSay questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "LookAndSayV2" && <LookAndSayV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "drag_shape" && <DragShape questions={exerciseData} nextExercise={nextExercise} />}


                        {exerciseData.type === "color_circle" && <ColorCircle questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "good_job" && <Goodjob userAnswers={userAnswers} questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "good_job_v2" && <GoodjobV userAnswers={userAnswers} questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "read_match_v1" && <ReadAndMatchV1 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_listen_say_v1" && <LookListenSayV1 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_listen_say_svg1" && <LookListenSayVSvg1 questions={exerciseData} nextExercise={nextExercise} />}


                        {exerciseData.type === "look_write_v4" && <LookAndWriteV4 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "read_your_dialog" && <ReadYourDialog questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "question_answer" && <CompleteV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "download_attach" && <Downloadattach questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "circle_letter" && <CircleLetter questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "circle_words" && <CircleWords questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "completeRev" && <CompleteV2Rev1 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "completeRev_lines" && <CompleteV2Rev1Line questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "completeRevForm" && <CompleteV2Rev1Form questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "completeRevChart" && <CompleteV2RevChart1 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "completeRev_v2" && <CompleteV2Rev1V2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "completeRevTable" && <CompleteRevTable questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "who_do_you_want_to_be" && <WhoDoYouWantToBe questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "order_words" && <OrderWords questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "question_answer_v2" && <CompleteV3 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "look_notice" && <LookNotice questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "look_notice_v2" && <LookNoticeV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "multiple_checkbox" && <MultipleCheckbox questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === "multiple_checkbox_count" && <MultipleCheckboxCount questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "color_circle_v2" && <ColorCircleV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "follow_instruction" && <Followinstruction questions={exerciseData} nextExercise={nextExercise} />}


                        {exerciseData.type === 'match_pairs' && <MatchingDemo questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === 'match_pairs2' && <MatchingDemo2 questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === 'MeetTheCharactersV2' && <MeetTheCharactersV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === 'dragtable' && <Dragtable questions={exerciseData} nextExercise={nextExercise} />}
                        {exerciseData.type === 'dragtable_v2' && <DragtableV2 questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === 'image_txt' && <ImageTxt questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === 'dragtable_text_v2' && <DragtableV2Text questions={exerciseData} nextExercise={nextExercise} />}

                        {exerciseData.type === "map_drag" && <MapDrag nextExercise={nextExercise} questions={exerciseData} />}

                        {exerciseData.type === "MeetTheCharactersL1" && <MeetTheCharactersL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "ListenAndPointL1" && <GameL1 nextExercise={nextExerciseV2} />}




                        {exerciseData.type === "DragAndDropL1" && <DragAndDropL1 nextExercise={nextExerciseV2} />}




                        {exerciseData.type === "LookAndSayL1" && <LookAndSayL1 nextExercise={nextExercise} />}

                        {exerciseData.type === "WatchVideoL1" && <WatchVideoL1 nextExercise={nextExercise} />}
                        {exerciseData.type === "ReadAndMatchL1" && <ReadAndMatchL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "SortingL1" && <SortingL1 nextExercise={nextExerciseV2} />}

                        {exerciseData.type === "ListenReadAndSayL1" && <ListenReadAndSayL1 nextExercise={nextExercise} />}
                        {exerciseData.type === "LookSayL1" && <LookSayL1 nextExercise={nextExercise} />}
                        {exerciseData.type === "ChooseAndHearL1" && <ChooseAndHearL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "LookAndReadAndWriteL1" && <LookAndReadAndWriteL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "TalkToFriendL1" && <TalkToFriendL1 nextExercise={nextExercise} />}

                        {exerciseData.type === "ListenAndReadL1" && <ListenAndReadL1 nextExercise={nextExercise} />}
                        {exerciseData.type === "CompleteL1" && <CompleteL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "LookListenSayL1" && <LookListenSayL1 nextExercise={nextExercise} />}
                        {exerciseData.type === "CircleHearL1" && <CircleHearL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "ReadNamesL1" && <ReadNamesL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "LookAndWriteL1" && <LookAndWriteL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "LookNoticeL1" && <LookNoticeL1 nextExercise={nextExerciseV2} />}
                        {exerciseData.type === "GoodJobL1" && <GoodJobL1 userAnswers={userAnswers} nextExercise={nextExercise} />}



                    </>
                }

                <div className='arrows'>
                    <div id="prev-bt" onClick={prevExercise}>{"<"}</div>
                    <div id="next-bt" onClick={nextExercise}>{">"}</div>
                </div>


            </div>
        </div>



    </div>)
}
export default Game;